import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoginModel } from '@3mk/shared/models/login.model';
import { Pagination } from '@3mk/shared/models/pagination.model';
import { CompanyModel } from '@3mk/shared/models/company.model';
import { SalesPointModel } from '@3mk/shared/models/SalesPoint.model';
import { DatesRangeDTO } from '../dto/date-range.dto';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  logIn(payload: LoginModel) {
    return this.http.post(`${this.apiUrl}/auth/salesMan/logIn`, payload);
  }

  getCompanies(payload: Pagination) {
    let params = new HttpParams({
      fromObject: {
        page: String(payload.page),
        limit: String(payload.limit),
        deleted: String(payload.deleted),
      },
    });

    if (payload.query) {
      params = params.append('query', payload.query);
    }
    return this.http.get(`${this.apiUrl}/company/findSalesManCompanies`, {
      params,
    });
  }

  saveCompany(company: CompanyModel) {
    return this.http.post(`${this.apiUrl}/company/asSalesMan`, company);
  }

  getCompanyAndSalesPoints(id: string) {
    return this.http.get(`${this.apiUrl}/company/${id}/salesMan/salesPoints`);
  }

  addSalesPoint(salesPoint: SalesPointModel) {
    return this.http.post(`${this.apiUrl}/salesPoint/asSalesMan`, {
      ...salesPoint,
    });
  }

  getSalesManSalesReport(dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(
      `${this.apiUrl}/sales-man/report/companies/successful`,
      {
        params,
        responseType: 'blob',
      },
    );
  }

  getSalesManErrorsReport(dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(`${this.apiUrl}/sales-man/report/companies/failed`, {
      params,
      responseType: 'blob',
    });
  }

  getSalesManExchangesReport(dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(
      `${this.apiUrl}/sales-man/report/companies/exchanges`,
      {
        params,
        responseType: 'blob',
      },
    );
  }

  getSalesManAssemblyErrorsReport(dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(
      `${this.apiUrl}/sales-man/report/companies/assemblyErrors`,
      {
        params,
        responseType: 'blob',
      },
    );
  }

  getCompanySalesReport(id: string, dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(
      `${this.apiUrl}/sales-man/report/company/${id}/successful`,
      {
        params,
        responseType: 'blob',
      },
    );
  }

  getCompanyErrorsReport(id: string, dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(
      `${this.apiUrl}/sales-man/report/company/${id}/failed`,
      {
        params,
        responseType: 'blob',
      },
    );
  }

  getCompanyExchangesReport(id: string, dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(
      `${this.apiUrl}/sales-man/report/company/${id}/exchanges`,
      {
        params,
        responseType: 'blob',
      },
    );
  }

  getCompanyAssemblyErrorsReport(id: string, dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(
      `${this.apiUrl}/sales-man/report/company/${id}/assemblyErrors`,
      {
        params,
        responseType: 'blob',
      },
    );
  }

  getOneCompany(id: string) {
    return this.http.get(`${this.apiUrl}/company/salesMan/${id}`);
  }

  getPatternListXls() {
    return this.http.get(`${this.apiUrl}/pattern/list/xls/salesMan`, {
      responseType: 'blob',
    });
  }
}
