var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { environment } from '../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ApiService = /** @class */ (function () {
    function ApiService(http) {
        this.http = http;
        this.apiUrl = environment.apiUrl;
    }
    ApiService.prototype.logIn = function (payload) {
        return this.http.post(this.apiUrl + "/auth/salesMan/logIn", payload);
    };
    ApiService.prototype.getCompanies = function (payload) {
        var params = new HttpParams({
            fromObject: {
                page: String(payload.page),
                limit: String(payload.limit),
                deleted: String(payload.deleted),
            },
        });
        if (payload.query) {
            params = params.append('query', payload.query);
        }
        return this.http.get(this.apiUrl + "/company/findSalesManCompanies", {
            params: params,
        });
    };
    ApiService.prototype.saveCompany = function (company) {
        return this.http.post(this.apiUrl + "/company/asSalesMan", company);
    };
    ApiService.prototype.getCompanyAndSalesPoints = function (id) {
        return this.http.get(this.apiUrl + "/company/" + id + "/salesMan/salesPoints");
    };
    ApiService.prototype.addSalesPoint = function (salesPoint) {
        return this.http.post(this.apiUrl + "/salesPoint/asSalesMan", __assign({}, salesPoint));
    };
    ApiService.prototype.getSalesManSalesReport = function (dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/sales-man/report/companies/successful", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getSalesManErrorsReport = function (dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/sales-man/report/companies/failed", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getSalesManExchangesReport = function (dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/sales-man/report/companies/exchanges", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getSalesManAssemblyErrorsReport = function (dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/sales-man/report/companies/assemblyErrors", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getCompanySalesReport = function (id, dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/sales-man/report/company/" + id + "/successful", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getCompanyErrorsReport = function (id, dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/sales-man/report/company/" + id + "/failed", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getCompanyExchangesReport = function (id, dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/sales-man/report/company/" + id + "/exchanges", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getCompanyAssemblyErrorsReport = function (id, dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/sales-man/report/company/" + id + "/assemblyErrors", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getOneCompany = function (id) {
        return this.http.get(this.apiUrl + "/company/salesMan/" + id);
    };
    ApiService.prototype.getPatternListXls = function () {
        return this.http.get(this.apiUrl + "/pattern/list/xls/salesMan", {
            responseType: 'blob',
        });
    };
    ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
